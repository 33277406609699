<template>
  <div class="navbar-container main-menu-content">
    <horizontal-nav-menu-items :items="getNavItems()" />
  </div>
</template>

<script>
import navMenuItems from '@/navigation/horizontal'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'

export default {
  components: {
    HorizontalNavMenuItems,
  },
  setup() {
    return {
      navMenuItems,
    }
  },
  methods: {
    getNavItems() {
      const items = []
      const isAuthenticated = this.$store.getters['auth/isAuthenticated']
      this.navMenuItems.forEach(item => {
        if (isAuthenticated) {
          if (item.authRequired && !item.hideAfterLogin) {
            items.push(item)
          } else if (!item.authRequired && !item.hideAfterLogin) {
            items.push(item)
          }
        }
        if (!isAuthenticated && !item.authRequired) {
          items.push(item)
        }
      })
      return items
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
